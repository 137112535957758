// 全局常量
//接口相关
window.REPORT_BASE_URL =
  "https://bajie-sleep.oss-cn-hangzhou.aliyuncs.com/formal/"; //下载用

window.LINK_URL = {
  officalWebsite: "https://www.bajiesleep.com/",
};

//缓存相关
window.CACHE_KEY = {
  loginInfo: "loginInfo",
  accountInfo: "accountInfo",
  sidebarStatus: "sidebarStatus",
  storeState: "storeState",
};

// 业务相关
//设备
window.DEVICE_BINDING_STATUS_CODE = {
  //未绑定用户
  闲置: 1,
  维修: 2,
  维保: 3,
  //绑定用户
  已借出: 4,
  待回收: 5,
};

window.DEVICE_WARNING_LEVEL = {
  低: 1,
  中: 2,
  高: 3,
};

window.DEVICE_RECYCLE_STATUS_CODE = {
  完好: 1,
  损坏: 2,
};

window.DEVICE_HANDLE_TYPE_CODE = {
  借出: 1,
  回收: 2,
};

//检测仪
window.MONITOR_WORK_STATUS_CODE = {
  未连接: 1,
  未监测: 2,
  离床: 3,
  监测中: 4,
  活动: 5,
};

window.MONITOR_AUTO_UPGRADE_STATUS = {
  关闭: 0,
  开启: 1,
};

window.MONITOR_NETWORK_TYPE = {
  移动信号: 0,
  wifi信号: 1,
  mifi信号: 9,
};

//戒指
window.RING_CONNECT_STATUS_CODE = {
  未连接: 0,
  连接中: 1,
  已连接: 2,
  断开中: 3,
};

window.RING_POWER_STATUS_CODE = {
  工作中: 0,
  充电中: 1,
  充满可工作: 2,
  低电设备只保持蓝牙其他模块关闭: 3,
};

window.RING_MONITOR_STATUS_CODE = {
  未开启戒指监测: -1,
  已开启戒指监测: 0,
  待收取血氧数据: 1,
  戒指数据传输完成: 2,
};

window.RING_AUTO_UPGRADE_STATUS = {
  关闭: 0,
  开启: 1,
};

//report
window.REPORT_STATUS_CODE = {
  当前设备不存在未收取的睡眠初筛报告: 0,
  当前设备存在未收取的睡眠初筛报告: 1,
};

window.REPORT_QUALITY_CODE = {
  正常: 1,
  缺少血氧: 2,
  睡眠时间过短: 3,
  监测时间过短: 4,
  无效报告: 5,
};

window.REPORT_COMPLETENESS_REQUEST_CODE = {
  全部: 0,
  完整: 1,
  无效: 2,
};

window.REPORT_COMPLETENESS_RESPONSE_CODE = {
  其他: 0,
  完整: 1,
  缺少血氧: 2,
  睡眠时间过短: 3,
  监测时间过短: 4,
  通电少于20分钟: 5,
};

window.AHI_LEVEL_CODE = {
  全部: 0,
  正常: 1,
  轻度: 2,
  中度: 3,
  重度: 4,
};

//user
window.GENDER_CODE = {
  男: "1",
  女: "2",
};

window.DEVICE_MANAGE_LIST_SORT_CODE = {
  默认: 0,
  绑定用户: 1,
};

window.SCENE_CODE = {
  院内: "1",
  院外: "2",
};

window.DEVICE_SEARCH_TYPE = {
  设备: 0,
  用户: 1,
};

window.DEVICE_MONITOR_MODE = {
  成人模式: 0,
  儿童模式: 1,
};

window.DEVICE_USE_PERIOD = {
  "1天": 1,
  "2天": 2,
  "3天": 3,
  "7天": 7,
};

window.CHILD_STANDDARD_TYPE_CODE = {
  "2007版中国指南": "1",
  "2020版中国指南/2014版美国指南": "2",
};

window.AHI_DEGREE_ARRAY = {
  成人: [5, 15, 30, 100],
  中国儿童: [5, 10, 20, 100],
  美国儿童: [1, 5, 10, 100],
};

window.REPORT_SUGGESTION_TYPE = {
  智能评估: "1",
  自由编辑: "2",
  空白模板: "3",
};

window.BLOOD_OXYGEN_STANDARD = {
  正常: 100, //值随便写
  轻度: 90,
  中度: 85,
  重度: 80,
};

window.MONITOR_CENTER_QUERY_TYPE = {
  全部设备: 0,
  在线设备: 1,
  异常设备: 2,
};

window.BLOOD_OXYGEN_LOG_SYSTEM_TYPE = [
  {
    value: 1,
    label: "儿童osa",
  },
  {
    value: 2,
    label: "初筛",
  },
  {
    value: 3,
    label: "八戒健康",
  },
];

window.BLOOD_OXYGEN_LOG_TYPE = [
  {
    value: "原始数据",
    label: "原始数据",
  },
  {
    value: "原始日志",
    label: "原始日志",
  },
  {
    value: "操作日志",
    label: "操作日志",
  },
];

window.QUERY_TYPE = {
  PHQ: 4,
  GAD: 5,
  PSQ: 13,
  OSA_18: 14,
  EQC: 15,
  Conners: 16,
  ESS: 17,
  STOP_Bang: 18,
  CNCQ: 19,
  BISQ: 20,
  CSHQ: 21,
  ISI: 22
};

window.RECEIVE_REPORT_WAY = {
  血氧筛查: 1,
  八戒健康: 2
};

window.REPORT_TEMPLATE = {
  台州恩泽医疗中心集团恩泽医院: 3,
  北京大学深圳医院呼吸内科睡眠检测中心: 4,
  四川省中医院: 5,
  山东省立医院集团鲁东医院: 6,
  舟山市第二人民医院: 7,
  中山大学附属第三医院·河源市中医院耳鼻喉科·广州市第十二人民医院·中山大学附属第八医院耳鼻喉科: 8,
  江门市人民医院耳鼻喉科: 10,
  南方医科大学顺德医院耳鼻咽喉头颈外科: 11,
  深圳市儿童医院: 12,
  山东省精神卫生中心: 13,
  广东省人民医院珠海医院呼吸科: 14,
  天水市中西医结合医院: 15, //title有两个医院，分行显示。全模版代码统一处理
  南京市儿童医院: 16,
  上海市第一人民医院酒泉医院:17
}

window.SPO2_REPORT_TEMPLATE = {
  广东医科大学附属第二医院: 1,
  中国曲棍球国家代表队: 2,
  河南省儿童医院耳鼻喉科: 3
}

//UI相关
window.MSG = {
  serverError: "服务器出错",
  accountOrPasswordError: "账户或密码错误!",
  phoneNumberOrVerifyCodeError: "手机号或验证码错误!",
  verifyCodeSendSucceed: "验证码发送成功",
  phoneNumberError: "请输入正确的手机号！",
  deviceInMaintain: "设备维保中，请稍后再试",
  deviceInRepair: "设备维修中，请稍后再试",
  invalidSN: "无效的设备SN",
  userIsEmpty: "用户不能为空！",
  lessThan4: "监测时长不能低于4小时！",
  moreThan12: "监测时长不能超过12小时！",
  msgSendSucceed: "短信发送成功",
  deviceDeleteSucceed: "设备删除成功！",
  snEmpty: "设备号不能为空",
  roomEmpty: "房间号不能为空",
  deviceAddSucceed: "设备添加成功！",
  deviceEditSucceed: "设备编辑成功！",
  deviceInUse: "设备使用中！请回收后再进行编辑操作！",
  userDeleteSucceed: "用户删除成功！",
  pickHospitalRemind: "请先指定医院！",
  settingAllHospitalError: "无法设置全部医院",
  necessaryEmpty: "必填信息不能为空",
  ageNotPosstiveNumber: "年龄必须为正数",
  heightNotPosstiveNumber: "身高必须为正数",
  weightNotPosstiveNumber: "体重必须为正数",
  userAddSucceed: "用户添加成功！",
  userEditSucceed: "用户编辑成功！",
  functionNotOpen: "功能暂未开放",
  notAPicture: "只能上传jpg/png/bmp格式！",
  pictureIsTooBig: "文件大小不能超过500K！",
  hospitalNoManager: "没有可用的管理员，请联系客服！",
  verifyCodeEmpty: "验证码为空",
  kickOut: "授权验证失败，请重新登录",
  reportEditSucceed: "报告编辑成功！",
  copySucceed: "access secret 复制成功",
  copyFailed: "access secret 复制失败",
  copyToClipboardSuccess: "已成功复制到剪贴板",
};

window.MSG_LIMIT_SECOND = 60;

window.BORROW_DAY_ARRAY = [1, 2, 3, 7];

window.MONITOR_HOUR_ARRAY = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

window.MONITOR_MINUTE_ARRAY = [00, 15, 30, 45];

// 函数表达式
//constant.js文件在main.js文件中被引入, 因此可以全局使用

//🍧操作常量对象
//通过value获取key
findKey = function (obj, value, compare = (a, b) => a === b) {
  return Object.keys(obj).find((k) => compare(obj[k], value));
};

//获取key数组
getObjectKeys = function (object) {
  var keys = [];
  for (var property in object) keys.push(property);
  return keys;
};

//获取value数组
getObjectValues = function (object) {
  var values = [];
  for (var property in object) values.push(object[property]);
  return values;
};

//🍧判断是否是图片
isPicture = function (str) {
  var strFilter = ["png", "jpg", "jpeg", "bmp"];
  if (str.indexOf(".") > -1) {
    var index = str.lastIndexOf(".");
    var ext = str.substring(index + 1);
    console.log(`后缀${ext}`);
    if (strFilter.indexOf(ext.toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
};

//🍧自定义错误信息
getWebErrorMsg = function (error) {
  switch (error.code) {
    case 10010:
    case 10004:
      return MSG.kickOut;
    case 10018:
      return MSG.invalidSN;
    case 10032:
      return MSG.deviceInMaintain;
    case 10033:
      return MSG.deviceInRepair;
    default:
      return error.msg;
  }
};

//🍧格式化时间
getTimeValue = function (value) {
  let result = parseInt(value);
  if (result >= 10) {
    return String(result);
  } else {
    return "0" + String(result);
  }
};

//🍧判断正数
isPositiveNumber = function (value) {
  const num = parseFloat(value).toString();
  if (num == "NaN") {
    return false;
  } else {
    return parseFloat(value) > 0;
  }
};

//Promise简化
to = function (promise) {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
};

// 占位文本
placeholderText = function (rawValue, unitText = "") {
  //当rawValue为undefined,null、NaN,0,""时显示占位符
  //当需要显示0值时，通过toFixed()方法转化为需要的字符串后传入
  if (!rawValue) {
    return "-";
  } else {
    const finalUnitText = unitText ? ` ${unitText}` : "";
    return `${rawValue}${finalUnitText}`;
  }
};

getHexColor = function getHexColor(color) {
  var values = color
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",");
  var a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return (
    "#" +
    ("0" + r.toString(16)).slice(-2) +
    ("0" + g.toString(16)).slice(-2) +
    ("0" + b.toString(16)).slice(-2)
  );
};

// 当前窗口显示最多的行数（不滚动的情况下）
bestPageSizeComputer = function (listHeight = 380, rowHeight = 54) {
  // const bestPageSize = Math.floor((window.innerHeight - listHeight) / rowHeight)
  //   return bestPageSize > 5 ? bestPageSize : 5
  return 10
}
